import React, { useState } from "react";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

import { Header } from "../components";
import gpayLogo from "../data/google-pay.svg";
import paytmLogo from "../data/paytmlogo.png";
import phonePayLogo from "../data/phonepe.svg";
import useAuth from "../useAuth";

const AutomaticPayments = () => {
  const [gpayEnabled, setGPayEnabled] = useState(false);
  const [paytmEnabled, setPaytmEnabled] = useState(false);
  const [phonePayEnabled, setPhonePayEnabled] = useState(false);

  useAuth();

  const iconSize = "90px";
  const iconMargin = "20px";

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Automatic Payments" />
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={gpayLogo}
            alt="GPay"
            style={{ height: iconSize, marginBottom: iconMargin }}
          />
          <SwitchComponent
            checked={gpayEnabled}
            change={(args) => setGPayEnabled(args.checked)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={paytmLogo}
            alt="Paytm"
            style={{ height: iconSize, marginBottom: iconMargin }}
          />
          <SwitchComponent
            checked={paytmEnabled}
            change={(args) => setPaytmEnabled(args.checked)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={phonePayLogo}
            alt="PhonePay"
            style={{ height: iconSize, marginBottom: iconMargin }}
          />
          <SwitchComponent
            checked={phonePayEnabled}
            change={(args) => setPhonePayEnabled(args.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default AutomaticPayments;
