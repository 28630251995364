// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhELAuFi6st7lKyKwGpGyRfKGFQuTbSbE",
  authDomain: "shatir-33307283.firebaseapp.com",
  databaseURL: "https://shatir-33307283-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "shatir-33307283",
  storageBucket: "shatir-33307283.appspot.com",
  messagingSenderId: "900471449323",
  appId: "1:900471449323:web:0d6b25b1e8c4624e5000ae",
  measurementId: "G-H544F2F789"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { app, auth };