import React, { useState, useEffect } from "react";
import Logo from "../data/16shatirlogo.png";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "./firebase-config";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import mainLogo from "../data/mainLogo.png";

const Login = () => {
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(app);
  
  useEffect(() => {
    // Check if user is already authenticated
    if (Cookies.get('authToken')) {
      navigate("/purchases");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user) {
        console.log(user);
        setError("");
        // Set the token as a cookie
        const authToken = await user.getIdToken();
        Cookies.set('authToken', authToken, { expires: 7 }); // Token expires in 7 days
        navigate("/purchases"); // Navigate to the purchases page upon successful login
      }
    } catch (error) {
      const errorMessage = "There's a problem signing in. Check your email and password.";
      setError(errorMessage);
      console.error("Login error:", error);
    }
  };
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={mainLogo} alt="16 Shatir Logo" className="h-40 mb-4" />
      <div className="w-full sm:w-96">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold justify mb-4 text-center text-blue-500">Login</h2>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700"></label>
              <input
                type="email"
                id="email"
                className="w-full border rounded-md px-4 py-2 mt-2 focus:outline-none focus:border-blue-500"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700"></label>
              <input
                type="password"
                id="password"
                className="w-full border rounded-md px-4 py-2 mt-2 focus:outline-none focus:border-blue-500"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-bold rounded-md px-4 py-2 mt-4 hover:bg-blue-600"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
