// useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = Cookies.get('authToken');

    if (!authToken) {
      navigate('/'); // Redirect to login page if authToken is not present
    }
  }, [navigate]);

};

export default useAuth;
