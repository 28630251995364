import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar, Footer, Sidebar } from "./components";
import {
  Ecommerce,
  AutomaticPayments,
  ApprovedWithdraw,
  Purchase,
  DeclinedPurchase,
  DeclinedWithdraw,
  Withdrawal,
} from "./pages";

import Login from "./login/Login";
import "./App.css";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (email, password) => {
    if (email === "email" && password === "password") {
      setIsLoggedIn(true);
      localStorage.setItem("loggedIn", "true");
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleLogout = () => {
    localStorage.setItem("loggedIn", "false");
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/purchases" />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route path="*" element={<ProtectedRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const ProtectedRoutes = () => {
  const currentColor = "#000"; // Example color, should be replaced with your logic

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
        <Sidebar />
      </div>
      
      <div className="dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full">
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div>
          <Routes>
            <Route path="/" element={<Purchase />} />
            <Route path="/purchases" element={<Purchase />} />
            <Route path="/approvedpurchase" element={<Ecommerce />} />
            <Route path="/declinedpurchase" element={<DeclinedPurchase />} />
            <Route path="/withdraw" element={<Withdrawal />} />
            <Route path="/approvedwithdraw" element={<ApprovedWithdraw />} />
            <Route path="/declinedwithdraw" element={<DeclinedWithdraw />} />
            <Route path="/automaticpayments" element={<AutomaticPayments />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
