import React, { useState, useEffect } from "react";
import { Header } from "../components";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import useAuth from "../useAuth";
import { formatTime } from "../timeFormat";
import Card from "../components/playerCard/Card";

const ApprovedWithdraw = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [player, setPlayer] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [playBalance, setPlayBalance] = useState(null);
  const [rewardBalance, setRewardBalance] = useState(null);
  const [copyMessage, setCopyMessage] = useState("");

  useAuth();

  useEffect(() => {
    fetchWithdrawals();
  }, [currentPage]);

  const fetchWithdrawals = async () => {
    try {
      const response = await fetch(
        `https://one6shatir-backend-api.onrender.com/getApprovedWithdrawals?page=${currentPage}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch withdrawals");
      }
      const data = await response.json();
      setWithdrawals(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawals:", error);
      setError("Failed to fetch withdrawals. Please try again later.");
      setLoading(false);
    }
  };

  const getPlayerBalance = async (userId) => {
    setPlayer(true);
    try {
      const pbResponse = await fetch(
        "https://one6shatir-backend-api.onrender.com/getPlayBalance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );
      const rbResponse = await fetch(
        "https://one6shatir-backend-api.onrender.com/getRewardBalance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );
      if (!pbResponse.ok || !rbResponse.ok) {
        throw new Error("Failed to fetch player balance");
      }
      const pbData = await pbResponse.json();
      const rbData = await rbResponse.json();
      setPlayer(false);
      setSelectedUserId(userId);
      setPlayBalance(pbData.playBalance);
      setRewardBalance(rbData.rewardBalance);
    } catch (error) {
      console.error("Error fetching player balance:", error);
    } finally {
      setPlayer(false);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const copyUserId = (userId) => {
    navigator.clipboard
      .writeText(userId)
      .then(() => {
        setCopyMessage("User ID copied to clipboard!");
        setTimeout(() => {
          setCopyMessage("");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  useEffect(() => {
    console.log(copyMessage);
  }, [copyMessage]);

  const closeCard = () => {
    setSelectedUserId(null);
  };

  const columns = [
    {
      field: "userId",
      headerText: "User ID",
      width: "120px",
      template: (rowData) => (
        <div>
          <button onClick={() => getPlayerBalance(rowData.userId)}>
            <u>{rowData.userId}</u>
          </button>
          <span
            onClick={() => copyUserId(rowData.userId)}
            style={{ cursor: "pointer" }}
          >
            🗒️
          </span>
          {copyMessage && <span>{copyMessage}</span>}
        </div>
      ),
    },
    { field: "upi_id", headerText: "UPI ID", width: "120px" },
    {
      field: "timestamp",
      headerText: "Timestamp",
      template: (rowData) => formatTime(rowData.timestamp),
      width: "120px",
    },
    { field: "txnId", headerText: "Transaction ID", width: "120px" },
    { field: "amount", headerText: "Amount", width: "120px" },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Approved Withdrawals" />
      {player ? <span>Fetching Player...</span> : <></>}
      {copyMessage && <span style={{ fontSize: "small" }}>{copyMessage}</span>}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <GridComponent dataSource={withdrawals} width="auto" allowSorting>
            <ColumnsDirective>
              {columns.map((col, index) => (
                <ColumnDirective key={index} {...col} />
              ))}
            </ColumnsDirective>
          </GridComponent>
          {selectedUserId && (
            <div className="card-container">
              <Card
                playerId={selectedUserId}
                playBalance={playBalance}
                rewardBalance={rewardBalance}
                onClose={closeCard}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <ButtonComponent
              cssClass="e-primary"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </ButtonComponent>
            <div style={{ margin: "0 10px" }}>Page {currentPage}</div>
            <ButtonComponent cssClass="e-primary" onClick={nextPage}>
              Next
            </ButtonComponent>
          </div>
        </>
      )}
    </div>
  );
};

export default ApprovedWithdraw;
