
import React from 'react';
import './Card.css';

export default function Card({ rewardBalance, playBalance, onClose }) {
  return (
    <div className="card">
      <div className="balance">
        <div className="balance-item">
          <span className="balance-label">Reward Balance:</span>
          <span className="balance-value">{rewardBalance}</span>
        </div>
        <div className="balance-item">
          <span className="balance-label">Play Balance:</span>
          <span className="balance-value">{playBalance}</span>
        </div>
      </div>
      <div className="separator"></div>
      <button className="close-button" onClick={onClose}>Close</button>
    </div>
  );
}