import React, { useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Header } from "../components";
import useAuth from "../useAuth";
import { formatTime } from "../timeFormat";
import Card from "../components/playerCard/Card";

const Withdrawal = () => {
  useAuth();
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [player, setPlayer] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [playBalance, setPlayBalance] = useState(null);
  const [rewardBalance, setRewardBalance] = useState(null);
  const [copyMessage, setCopyMessage] = useState("");
  useEffect(() => {
    fetchWithdrawals();
  }, [currentPage]);

  const fetchWithdrawals = async () => {
    try {
      const requestOptions = {
        method: "GET",
      };

      const response = await fetch(
        `https://one6shatir-backend-api.onrender.com/getWithdrawals?page=${currentPage}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch withdrawals");
      }
      const data = await response.json();
      setWithdrawals(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawals:", error);
      setError("Failed to fetch withdrawals. Please try again later.");
      setLoading(false);
    }
  };

  const approveWithdraw = async (txnId) => {
    console.log(txnId);

    try {
      const response = await fetch(
        "https://one6shatir-backend-api.onrender.com/admin/processWithdraw",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header
            // Add any additional headers if needed
          },
          body: JSON.stringify({
            txnId: txnId,
            status: 1,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to approve withdrawal");
      }

      fetchWithdrawals();
    } catch (error) {
      console.error("Error approving withdrawal:", error);
      // Handle error if needed
    }
  };

  const declineWithdraw = async (txnId) => {
    try {
      const response = await fetch(
        "https://one6shatir-backend-api.onrender.com/admin/processWithdraw",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header
            // Add any additional headers if needed
          },
          body: JSON.stringify({
            txnId: txnId,
            status: -1, // Use -1 to indicate a declined status
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to decline withdrawal");
      }

      fetchWithdrawals();
    } catch (error) {
      console.error("Error declining withdrawal:", error);
    }
  };

  const getPlayerBalance = async (userId) => {
    setPlayer(true);
    try {
      const pbResponse = await fetch(
        "https://one6shatir-backend-api.onrender.com/getPlayBalance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );
      const rbResponse = await fetch(
        "https://one6shatir-backend-api.onrender.com/getRewardBalance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );
      if (!pbResponse.ok || !rbResponse.ok) {
        throw new Error("Failed to fetch player balance");
      }
      const pbData = await pbResponse.json();
      const rbData = await rbResponse.json();
      setPlayer(false);
      setSelectedUserId(userId);
      setPlayBalance(pbData.playBalance);
      setRewardBalance(rbData.rewardBalance);
    } catch (error) {
      console.error("Error fetching player balance:", error);
    } finally {
      setPlayer(false);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const closeCard = () => {
    setSelectedUserId(null);
  };
  const copyUserId = (userId) => {
    navigator.clipboard
      .writeText(userId)
      .then(() => {
        setCopyMessage("User ID copied to clipboard!");
        setTimeout(() => {
          setCopyMessage("");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  useEffect(() => {
    console.log(copyMessage);
  }, [copyMessage]);
  const columns = [
    {
      field: "userId",
      headerText: "User ID",
      width: "120px",
      template: (rowData) => (
        <div>
          <button onClick={() => getPlayerBalance(rowData.userId)}>
            <u>{rowData.userId}</u>
          </button>
          <span
            onClick={() => copyUserId(rowData.userId)}
            style={{ cursor: "pointer" }}
          >
            🗒️
          </span>
          {copyMessage && <span>{copyMessage}</span>}
        </div>
      ),
    },
    {
      field: "timestamp",
      headerText: "Timestamp",
      template: (rowData) => formatTime(rowData.timestamp),
      width: "120px",
    },
    { field: "upi_id", headerText: "UPI ID", width: "120px" },
    { field: "txnId", headerText: "Txn Id", width: "120px" },
    { field: "amount", headerText: "Amount", width: "120px" },
    {
      headerText: "Actions",
      template: (rowData) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "120px",
            gap: "15px",
          }}
        >
          <ButtonComponent
            cssClass="e-success"
            onClick={() => approveWithdraw(rowData.txnId)}
          >
            Approve
          </ButtonComponent>
          <ButtonComponent
            cssClass="e-primary"
            onClick={() => declineWithdraw(rowData.txnId)}
          >
            Decline
          </ButtonComponent>
        </div>
      ),
      width: "150",
      textAlign: "Center",
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Withdrawals" />
      {player ? <span>Fetching Player...</span> : <></>}
      {copyMessage && <span style={{ fontSize: "small" }}>{copyMessage}</span>}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <GridComponent dataSource={withdrawals} width="auto" allowSorting>
            <ColumnsDirective>
              {columns.map((col, index) => (
                <ColumnDirective key={index} {...col} />
              ))}
            </ColumnsDirective>
          </GridComponent>
          {selectedUserId && (
            <div className="card-container">
              <Card
                playerId={selectedUserId}
                playBalance={playBalance}
                rewardBalance={rewardBalance}
                onClose={closeCard}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <ButtonComponent
              cssClass="e-primary"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </ButtonComponent>
            <div style={{ margin: "0 10px" }}>Page {currentPage}</div>
            <ButtonComponent cssClass="e-primary" onClick={nextPage}>
              Next
            </ButtonComponent>
          </div>
        </>
      )}
    </div>
  );
};

export default Withdrawal;
