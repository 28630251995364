export function formatTime(timestamp) {
    // Create a new Date object using the timestamp (in milliseconds)
    var date = new Date(timestamp * 1000);

    // Add 5 hours and 30 minutes for IST offset
    date.setUTCHours(date.getUTCHours() + 5);
    date.setUTCMinutes(date.getUTCMinutes() + 30);

    // Get hours, minutes, seconds, day, month
    var hours = date.getUTCHours().toString().padStart(2, '0');
    var minutes = date.getUTCMinutes().toString().padStart(2, '0');
    var seconds = date.getUTCSeconds().toString().padStart(2, '0');
    var day = date.getUTCDate().toString().padStart(2, '0');
    var month = (date.getUTCMonth() + 1).toString().padStart(2, '0');

    // Format the output string
    var formattedString = hours + ':' + minutes + ':' + seconds + ' ' + day + '/' + month;

    return formattedString;
}
